import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'

export const firebaseConfig = {
  apiKey: 'AIzaSyCYWHf5TVQjKqXjRsLJcvztYrt8-Pw82lI',
  authDomain: 'cryptolio-app.firebaseapp.com',
  databaseURL: 'https://cryptolio-app-default-rtdb.firebaseio.com/',
  projectId: 'cryptolio-app',
  storageBucket: 'cryptolio-app.appspot.com',
  messagingSenderId: '788403869739',
  appId: '1:788403869739:web:9dd9df33c4f52a2b7cb26e',
  measurementId: 'G-KM8QPF71Q9'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const firebaseDatabase = firebaseApp.database()
